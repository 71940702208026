/*
 * Global stylings that modify Tailwind + DaisyUI defaults.
 * NOTE: this strays from utility-first fundamentals; favor local styling over global when possible.
 * Only add stylings here that should apply to every instance of a class across the site.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ---- Fonts ---- */

@font-face {
  font-family: "Gilroy-Medium";
  src: url("fonts/Gilroy-Medium.otf");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("fonts/Gilroy-SemiBold.otf");
}

/* ---- Base Overrides ---- */

@layer base {
  * {
    @apply tracking-tight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    @apply text-4xl;
    @apply lg:text-6xl;
  }
  h2 {
    @apply text-3xl;
    @apply lg:text-5xl;
  }
  h3 {
    @apply text-lg;
    @apply lg:text-2xl;
  }
  p {
    @apply leading-relaxed;
    @apply text-base-mobile;
    @apply lg:text-base;
  }
}

/* ---- Component Overrides ---- */

@layer components {
  /* ---- Custom ---- */

  .snap-scroll-page {
    @apply flex-col;
    @apply relative;
    @apply h-[calc(100vh-theme('spacing.navbar'))];
    @apply snap-y;
    /* Only enforce snap scrolling on smaller screens (ie. mobile devices) */
    @apply snap-mandatory;
    @apply lg:snap-none;
    @apply overflow-y-auto;
    @apply mt-navbar;
  }

  .border-default {
    @apply border;
    @apply border-neutral-200;
  }

  /* ---- TailwindCSS ---- */

  .list-disc,
  .list-decimal,
  .list-check,
  .list-dash {
    @apply ml-8;
  }

  /* ---- DaisyUI ---- */

  .daisy-textarea {
    @apply rounded-3xl;
    @apply font-medium;
    @apply text-base-mobile;
    @apply lg:text-base;
  }

  .daisy-btn,
  .daisy-select,
  .daisy-input {
    @apply rounded-full;
    @apply px-button;
    @apply font-medium;
    @apply text-base-mobile;
    @apply lg:text-base;
  }

  .daisy-btn-sm {
    @apply text-sm;
  }

  .daisy-btn-primary {
    @apply hover:bg-secondary;
    @apply hover:text-secondary-content;
    @apply hover:border-secondary;
  }

  .daisy-btn-secondary {
    @apply hover:bg-primary;
    @apply hover:text-primary-content;
    @apply hover:border-primary;
  }

  .daisy-btn-neutral {
    @apply text-base-content;
  }

  .daisy-btn-link {
    @apply p-0;
  }

  .daisy-carousel {
    @apply px-0;
    @apply py-2;
  }

  .daisy-badge {
    @apply py-4;
  }

  .daisy-btn-ghost {
    @apply px-2;
    @apply hover:bg-neutral;
  }

  .daisy-menu {
    @apply p-0;
    @apply gap-1;
    @apply items-center;
  }

  .daisy-menu > li > a {
    @apply rounded-full;
    @apply hover:bg-neutral;
  }

  .daisy-collapse {
    @apply rounded-3xl;
  }

  .daisy-tooltip {
    /* Limit tooltip text from expanding off the page on mobile */
    @apply before:max-md:max-w-48;
    @apply before:whitespace-pre-wrap;
  }

  .daisy-skeleton {
    @apply rounded-3xl;
  }

  .daisy-modal-box {
    @apply rounded-3xl;
    @apply p-0;
  }

  /* Prevent iFrames inside modals from consuming pointer events */
  .daisy-modal:not(.daisy-modal-open) iframe {
    @apply hidden;
  }

  /* ---- Hero Icons ---- */

  .hero-icon {
    @apply size-8;
  }

  .hero-icon-sm {
    @apply size-6;
  }

  .hero-icon-xs {
    @apply size-3;
  }
}
